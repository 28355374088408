import { Controller } from '@hotwired/stimulus';
import { useClickOutside } from 'stimulus-use';

export default class extends Controller {
  static targets = [
    'panel',
  ];

  connect() {
    useClickOutside(this, { element: this.panelTarget });

    this.element.classList.replace('opacity-0', 'opacity-100');

    this.panelTarget.classList.replace('opacity-0', 'opacity-100');
    this.panelTarget.classList.replace('translate-y-4', 'translate-y-0');
    this.panelTarget.classList.replace('sm:scale-95', 'sm:scale-100');
    this.panelTarget.classList.remove('sm:translate-y-0');
  }

  disconnect() {
    this.close();
  }

  clickOutside() {
    this.close();
  }

  close() {
    this.element.addEventListener('transitionend', () => {
      this.element.remove();
    });

    this.element.classList.replace('ease-out', 'ease-in');
    this.element.classList.replace('duration-300', 'duration-200');
    this.element.classList.replace('opacity-100', 'opacity-0');

    this.panelTarget.classList.replace('ease-out', 'ease-in');
    this.panelTarget.classList.replace('duration-300', 'duration-200');
    this.panelTarget.classList.replace('opacity-100', 'opacity-0');
    this.panelTarget.classList.replace('translate-y-0', 'translate-y-4');
    this.panelTarget.classList.replace('sm:scale-100', 'sm:scale-95');
    this.panelTarget.classList.add('sm:translate-y-0');
  }
}
