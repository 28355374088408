import { Controller } from '@hotwired/stimulus';
import debounce from '../support/debounce';

export default class extends Controller {
  connect() {
    this.processGlobalSearchInput = debounce(() => this.submit());
  }

  submit(event) {
    if (event) { event.preventDefault(); }
    this.element.requestSubmit();
  }

  /* eslint-disable class-methods-use-this */
  clickClearAll(event) {
    event.preventDefault();

    const currentPath = window.location.pathname;
    // NOTE: A regular page reload is used rather than a turbo links visit
    // because in the latter the menu briefly pops up after reload. This only
    // happen when any filters have been selected in the menu.
    // Turbo.visit(currentPath);
    window.location = currentPath;
  }
  /* eslint-enable class-methods-use-this */
}
