import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'content',
    'placeholder',
    'item',
    'spinner',
  ];

  static values = {
    activePath: String,
    remote: Boolean,
  };

  connect() {
    if (this.remoteValue) {
      this.showSpinner();
    }
  }

  select(event) {
    event.preventDefault();
    this.activePathValue = event.params.path;
  }

  activePathValueChanged(currentPath) {
    let itemFound = false;

    this.itemTargets.forEach((item) => {
      const path = item.querySelector('a').dataset.accordionComponentPathParam;
      const icon = item.querySelector('.accordion-item-icon');
      const name = item.querySelector('.accordion-item-name');
      const subItems = item.querySelector('.accordion-item-sub-items');

      if (currentPath === path) {
        itemFound = true;
        this.markItemAsActive(icon, name, subItems);
        if (this.remoteValue) {
          this.renderRemote(item);
        } else {
          this.renderLocal(item);
        }
      } else if (currentPath.split('/').slice(0, path.split('/').length).join('/') === path) {
        this.expandItem(icon, name, subItems);
      } else {
        this.collapseItem(icon, name, subItems);
      }
    });

    if (!itemFound) {
      this.contentTarget.innerHTML = this.placeholderTarget.innerHTML;
    }
  }

  renderLocal(item) {
    if (item.querySelector('template').innerHTML.trim().length === 0) {
      this.contentTarget.innerHTML = this.placeholderTarget.innerHTML;
    } else {
      this.contentTarget.innerHTML = item.querySelector('template').innerHTML;
    }
  }

  async renderRemote(item) {
    this.showSpinner();
    const response = await fetch(item.querySelector('a').dataset.accordionComponentLinkParam);
    if (response.redirected) {
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    } else if (response.ok) {
      this.contentTarget.innerHTML = await response.text();
    } else {
      this.contentTarget.innerHTML = this.placeholderTarget.innerHTML;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  markItemAsActive(icon, name, subItems) {
    if (icon != null) {
      icon.classList.remove('rotate-0');
      icon.classList.add('rotate-90', 'text-primary-700');
    }
    name.classList.add('font-medium', 'text-primary-700');
    subItems.classList.remove('max-h-0');
    subItems.classList.add('max-h-500');
  }

  // eslint-disable-next-line class-methods-use-this
  expandItem(icon, name, subItems) {
    if (icon != null) {
      icon.classList.remove('rotate-0', 'text-primary-700');
      icon.classList.add('rotate-90');
    }
    name.classList.remove('text-primary-700');
    name.classList.add('font-medium');
    subItems.classList.remove('max-h-0');
    subItems.classList.add('max-h-500');
  }

  // eslint-disable-next-line class-methods-use-this
  collapseItem(icon, name, subItems) {
    if (icon != null) {
      icon.classList.add('rotate-0');
      icon.classList.remove('rotate-90', 'text-primary-700');
    }
    name.classList.remove('font-medium', 'text-primary-700');
    subItems.classList.add('max-h-0');
    subItems.classList.remove('max-h-500');
  }

  showSpinner() {
    if (!this.contentTarget.querySelector('#accordion-overlay')) {
      this.contentTarget.insertAdjacentHTML('beforeend', this.spinnerTarget.innerHTML);
      setTimeout(() => { this.contentTarget.querySelector('#accordion-overlay').classList.remove('opacity-0'); }, 500);
    }
  }
}
